import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { Box, Button, Grid, Paper, Typography } from "@material-ui/core"
import Layout from "../components/layout/main"
import Lead from "../components/global/text/lead"
import Seo from "../components/global/seo/seo.jsx"
const Hakkimizda = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "filtre-galeri/emisfiltresi4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 610) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <Seo
        title="Hakkımızda - Paslanmaz Filtre Erez Kaynak " 
        description="Firmamız Erez kaynak 2015 yılın da yenilenmeye giderek, günümüze kadar çelik hasır ve filtre imalat sektöründe yüksek titizlikle çalışarak özel bir yer edinmiştir.Paslanmaz 304 , paslanmaz 316 kalite yada dkp malzemeden, Müşterilerimizin istekleri doğrultusunda istenilen şekil, numune"
        keywords="erez kaynak şirketi, uğur erkin demirkaya, erez kaynak paslanmaz filtre ve elek imalatı"
        url="hakkimizda"
        imgurl={data.file.childImageSharp.fluid.src}
      />
      <Box mt={3}>
        <Paper elevation={4}>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={12} sm={12} md={12} lg={7}>
              <Box p={3}>
                <Typography variant="h4" component="h1">
                  <Box fontWeight={500}>Hakkımızda</Box>
                </Typography>
                <Lead>
                  Firmamız <span>“EreZ KaynaK”</span> 2015 yılında yenilenmeye
                  giderek, günümüze kadar{" "}
                  <span>çelik hasır ve filtre imalat sektöründe</span> yüksek
                  titizlikle çalışarak özel bir yer edinmiştir.
                </Lead>
                <Lead>
                  <span>
                    Paslanmaz 304 , paslanmaz 316 kalite ya da dkp malzemeden
                  </span>{" "}
                  müşterilerimizin istekleri doğrultusunda istenilen şekil,
                  numune ya da projeye göre <span>Filtre Ve Elek imalatı</span>{" "}
                  gerçekleştirmekteyiz.
                </Lead>
                <Lead>
                  <span>Paslanmaz, galvanize ya da çelik telden,</span>{" "}
                  müşterilerimizin istekleri doğrultusun da istenilen tel
                  kalınlığında ve ebatta, göz aralığı fark etmeksizin{" "}
                  <span>punta kaynaklı hasır (çesan)</span> imalatı
                  gerçekleştirmekteyiz.
                </Lead>
                <Lead>
                  Makinelerimizin kalıpları en büyük ölçüsü 2200x3000mm ebatında
                  olup, göz aralıkları <span>15mm, 20mm, 25mm</span>{" "}
                  ölçülerinde ve katlarında (Örn; 25×50 50×50 15×45 15×75 vb.)
                  ya da siparişe göre özel kalıp yapılarak istenilen göz
                  aralığında ve ebatlarda{" "}
                  <span>paslanmaz, galvanize ya da siyah çelik tel</span>{" "}
                  malzemesinden üretim gerçekleştirebiliyoruz.
                </Lead>
              </Box>
              <Box textAlign="center" pb={3}>
                <Link to="iletisim" style={{ textDecoration: "none" }}>
                  <Button variant="outlined" color="secondary">
                    İLETİŞİM BİLGİLERİ
                  </Button>
                </Link>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={5}>
              <div className="img-wrapper">
                <Box p={3}>
                  <Img
                    className="MuiPaper-elevation5 MuiPaper-rounded"
                    fluid={data.file.childImageSharp.fluid}
                    alt="Erez Kaynak"
                  />
                </Box>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Layout>
  )
}
export default Hakkimizda
